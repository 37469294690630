.textEditorContainer {
    margin: 10px;
    width: calc(100% - 20px);
    position: relative;
    background-color: white;
    border-radius: 15px;
    height: 40%;
    min-height: 130px;
    max-height: 300px;
}

.divButton {
    position: absolute;
    bottom: 10px;
    right: 15px;
    z-index: 3;
}
