.container {
    width: 400px;
}

.message label {
    font-size: 17px;
}

.message {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.messageLbl {
    text-align: center;
}
