.DraftEditor-root {
    height: unset;
}

.rdw-option-wrapper {
    border-radius: 7px;
    background-color: transparent;
    border: 1px solid var(--outlineGrey);
}
.rdw-option-wrapper:active {
    border: 1px solid var(--editorFocusColor);
}
.rdw-option-wrapper:hover {
    box-shadow: none;
    border: 1px solid var(--editorFocusColor);
}

.rdw-option-active {
    border: 1px solid var(--editorFocusColor);
    box-shadow: none;
}

.rdw-editor-toolbar {
    border-radius: 15px 15px 0 0;
    border: none;
    border-bottom: 1px solid var(--outlineGrey);
    background-color: transparent;
}

.ErkC-TextEditorContainer .rdw-editor-main {
    cursor: text;
    padding-top: 12px;
    padding-bottom: 40px;
    margin-right: 0px;
}

.public-DraftStyleDefault-block {
    margin: 0px 0;
    max-width: 100%;
}

.rdw-image-imagewrapper {
    max-width: 100%;
    /* cursor: pointer; */
    position: relative;
}

.rdw-image-alignment-editor {
    padding-right: 10px;
    padding-left: 5px;
    margin-left: -5px;
}
.rdw-editing-image {
    border: 1px solid var(--editorFocusColor);
}

.DraftEditor-root figure {
    margin: 0px;
}

.rdw-image-alignment-option {
    font-size: 16px !important;
    width: 20px;
    height: 20px;
    margin-left: 2px;
    border-radius: 2px;

    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--light-grey);
}

.rdw-image-alignment-options-popup {
    width: fit-content;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
}

/* Hides empty placeholder */
.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}
