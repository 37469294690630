.confirmationContainer {
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-height: 80vh;
    max-width: 80vw;
    border-radius: 10px;
    position: relative;
}

.confirmContainer {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
}

.checkContainer {
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    height: fit-content;
    margin-bottom: 15px;
}

.messageContainer {
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 25px;
    font-size: 20px;
    text-align: center;
}
