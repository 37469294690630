.textFieldContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 5;
    padding-bottom: 3px;
}
.textField {
    width: calc(100% - 51px);
    margin-left: 3px;
    position: relative;
    background-color: white;
    border-radius: 15px;
    height: fit-content;
}
