.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.titleContainer {
    width: 100%;
    position: relative;
    padding-bottom: 5px;
    margin-top: 5px;
    height: 30px;
    border-bottom: 1px solid var(--light-grey);
}

.overflowContainer {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.lblSubject {
    color: var(--accent);
    font-size: 24px;
    height: 30px;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
}

.listContainer {
    position: relative;
    flex-grow: 1;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.listCont {
    overflow-y: scroll;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column-reverse;
    display: flex;
    align-items: flex-end;
}

.listContCont {
    width: 100%;
    display: flex;
    flex-direction: column;
}
