.textContainer {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
    line-height: 22px;
    cursor: default;
}

.textContainer {
    padding: 5px;
    padding-left: 10px;
}
.lblFile {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    margin-left: auto;
    margin-right: 5px;
}

.filesContainer {
    padding: 5px;
    padding-left: 2px;
    display: flex;
}

.fileContainer {
    padding-right: 5px;
    max-width: 100%;
}
