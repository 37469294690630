.infoPanel {
    overflow-y: auto;
    max-height: 100%;
}
.infoTitle {
    text-align: center;
    text-decoration: underline;
}

.infoRows {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    margin-top: 10px;
    overflow-y: scroll;
}

.horizontalDiv {
    height: 1px;
    margin: 0px 20px;
    background-color: var(--light-grey);
}

.infoRows p {
    font-size: 16px;
    margin: 10px 20px 0px 20px;
    padding-bottom: 10px;
}

.horizontalDivTitle {
    height: 1px;
    width: 100%;
    background-color: var(--grey);
    margin: 0 auto 0 auto;
}

.formBtnContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 15px;
}

.processContainer {
    margin-left: 30px;
}
