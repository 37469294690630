.navContainer {
    height: 60px;
    display: flex;
}
.navImage {
    height: 40px;
    margin: auto 0 auto 15px;
}

.toggle {
    margin: auto 5px auto auto;
}

@media screen and (max-width: 768px), screen and (max-height: 420px) {
    .toggle {
        display: unset !important;
    }
}
