.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 30px;
    max-width: 100%;
    overflow: hidden;
    overflow-y: auto;
}

.containerLbl {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
}

.descriptionLbl {
    font-size: 18px;
    margin-top: 15px;
}

.textContainer {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
    line-height: 22px;
    cursor: default;
}

.msgContainer {
    border-radius: 0px 0px 10px 10px;
    border: 2px solid var(--light-grey);
    border-top: 0;
    width: 600px;
    max-width: 100%;
}

.filesContainer {
    margin-top: auto;
    padding: 5px;
    display: flex;
}

.filesContainer {
    display: flex;
    min-height: 35px;
}
.fileContainer {
    padding-right: 5px;
    max-width: 100%;
}

.emptyFiles {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 6px;
    font-size: 16px;
    font-weight: 600;
}

.actionTitleContainerUser {
    padding-left: 8px;
    padding-right: 8px;
    height: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid var(--light-grey);
    cursor: default;
    color: var(--primary1);
    border-radius: 10px 10px 0 0;
    width: 584px;
    max-width: calc(100% - 16px);
    margin-top: 20px;
}
.actionTitle,
.actionDate {
    font-size: 15px;
    white-space: nowrap;
}

.actionDate {
    margin-top: 2px;
    font-size: 13px;
    margin-left: auto;
}
.actionTitle {
    margin-top: 1px;
    margin-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.boldName {
    font-weight: bold;
    margin-right: 5px;
}

.btnsContainer {
    display: flex;
    margin-top: 25px;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-bottom: 25px;
}
.btn {
    margin-left: 30px;
}
