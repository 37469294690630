.attachButton {
    border: 1px solid #f1f1f1;
    padding: 5px;
    width: 37px;
    height: 32px;
    border-radius: 7px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
    border: 1px solid var(--light-grey);
    font-size: 22px;
    position: relative;
}
.attachButton:hover {
    border: 1px solid var(--greyBtn);
}

.countContainer {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    top: -3px;
    right: -5px;
}
.countLbl {
    font-size: 10px !important;
    font-family: 'Nunito';
    background-color: var(--contrast);
    font-weight: 600;
    color: white;
    height: 12px;
    padding-top: 3px;
    min-width: 10px;
    text-align: center;
    padding-bottom: 1px;
    border-radius: 20px;
    padding-left: 3px;
    padding-right: 3px;
    cursor: pointer;
}

.errorPopper {
    height: 24px;
    display: flex;
    align-items: center;
    margin-top: 6px;
    margin-left: 3px;
    min-width: 60px;
    width: fit-content;
    position: absolute;
    padding-right: 5px;
    border-radius: 6px;
    padding-left: 5px;
    border: 2px solid var(--error);
    background-color: var(--error);
    z-index: 4;
}
.errorPopperLbl {
    color: var(--primary1);
    font-weight: 600 !important;
    font-size: 15px !important;
    white-space: nowrap;
}
.errorArrow {
    border: 2px solid var(--error);
    height: 10px;
    width: 10px;
    margin-top: 2px;
    margin-left: 17px;
    z-index: 1;
    border-radius: 2px;
    position: absolute;
    background-color: var(--error);
    transform: rotateZ(45deg);
}

.btnContainer {
    position: relative;
}
