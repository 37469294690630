.container {
    width: 1000px;
    min-height: 700px;
    max-height: 80vh;
    max-width: calc(80vw - 40px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.widgetContainer {
    height: fit-content;
    width: fit-content;
    max-width: 100%;
    min-height: 400px;
    width: 980px;
}
@media screen and (max-width: 768px), screen and (max-height: 420px) {
    .container {
        padding: 10px;
        max-width: calc(90vw - 20px);
    }
}

.helperMessageLbl {
    margin-top: 20px;
    font-size: 26px;
    font-weight: 600;
}
