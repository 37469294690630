.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
}

.loading {
    position: relative;
    height: 100%;
}
.notFound {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card {
    margin: 15px 25px 0px 25px;
    display: flex;
    flex-direction: row;
    border-radius: 15px;
    background-color: white;
    height: calc(100% - 30px);
}

.activityContainer {
    height: 100%;
    border-radius: 15px 0 0 15px;
    border-right: 1px solid var(--light-grey);
    flex-grow: 1;
}

.informationContainer {
    width: 25%;
    min-width: 260px;
    border-radius: 0px 15px 15px 0px;
    overflow: hidden;
}

.copyrightContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
}
.copyright {
    font-size: 12px;
    margin: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.infoTitle {
    text-align: center;
    text-decoration: underline;
}

.messages {
    height: 60%;
    border-radius: 15px 0 0 0;
    flex-grow: 1;
    display: flex;
    flex-flow: column;
}
