.container {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
}

.helperMessageLbl {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 10px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.formDialogContainer {
    padding: 20px;
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerLbl {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
}
