.container {
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
}

.avatarContainer {
    width: 50px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.actionTitleContainer,
.actionTitleContainerUser {
    padding-left: 8px;
    padding-right: 8px;
    height: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid var(--light-grey);
    cursor: default;
}

.actionTitleContainer {
    background-color: var(--light-grey);
    color: var(--accent);
}

.actionTitleContainerUser {
    color: var(--primary1);
}

.actionTitle,
.actionDate {
    font-size: 15px;
    white-space: nowrap;
}

.actionTitle {
    margin-top: 1px;
    margin-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.actionDate {
    margin-top: 2px;
    font-size: 13px;
    margin-left: auto;
}

.actionContainer {
    display: flex;
    flex-direction: column;
    max-width: 75%;
    min-width: 320px;
    position: relative;
}

.actionContentContainer {
    border: 2px solid var(--light-grey);
    border-top: 0;
    border-radius: 0px 0px 10px 10px;
}

.boldName {
    font-weight: bold;
    margin-right: 5px;
}
.interactionImg {
    height: 15px;
    width: 15px;
    top: -5px;
    position: absolute;
}
@media screen and (max-width: 768px), screen and (max-height: 420px) {
    .actionContainer {
        max-width: calc(100vw - 45px);
        min-width: 75vw;
        margin-left: 5px;
        margin-right: 5px;
    }
    .container {
        margin-left: 5px;
        margin-right: 5px;
    }
    .avatarContainer {
        min-width: 30px;
        width: 30px;
    }
}
