.titleLbl {
    font-size: 30px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}
.activityLbl {
    font-size: 26px;
    margin-left: 15px;
    width: calc(100% - 15px);
    margin-top: 10px;
}

.container {
    width: 100%;
    min-height: 100vh;
    background-color: white;
}

.listContCont {
    width: calc(100% - 10px);
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    padding-right: 10px;
}

.empty {
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
}

.creationDate {
    font-size: 24px;
    font-weight: 600;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 5px;
    margin-left: 10px;
}

.erkForm {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}
