.container {
    width: 1000px;
    min-height: 700px;
    max-width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
}

.widgetContainer {
    height: fit-content;
    width: fit-content;
    max-width: calc(100% - 80px);
    min-height: 400px;
    width: 980px;
    padding: 20px;
}

@media screen and (max-width: 768px), screen and (max-height: 420px) {
    .container {
        max-width: 90vw;
    }

    .widgetContainer {
        padding: 0px;
        max-width: 100%;
    }
}
