.formBtnContainer {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    row-gap: 10px;
}
