.textContainer {
    height: 100%;
    border-radius: 15px;
    font-size: 15px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.textContainer div {
    font: inherit;
    font-size: 15px;
}

.editor {
    margin: -5px 10px 0px 0px;
    padding-left: 10px;
    height: calc(100% - 46px) !important;
    width: 100%;
    /* max-width: 100% !important; */
}

.wrapper {
    height: 100%;
    border-radius: 15px;
}

.disabledCurtain {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.launchBtn {
    border: 1px solid #f1f1f1;
    padding: 5px;
    width: 37px;
    height: 32px;
    border-radius: 7px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
    border: 1px solid var(--light-grey);
    font-size: 30px;
    position: relative;
    margin-left: auto;
}

.launchBtn:hover {
    border: 1px solid var(--greyBtn);
}
